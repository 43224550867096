import request from '../utils/request';
import qs from "qs";

//获取标签
export const recordLabel = param => {
	return request.get('/api/company/recordLabel?userId=' + param.userId+ '&&companyId=' + param.companyId)
};
//增加标签
export const addRecordLabel = param => {
	return request.post('/api/company/addRecordLabel', qs.stringify(param))
};
//删除标签
export const deleteRecordLabel = param => {
	return request.post('/api/company/deleteRecordLabel', qs.stringify(param))
};
//修改标签

//坐席数据列表
export const getfindStatus = param => {
	return request.post('/api/user/findStatus', qs.stringify(param))
};

//导出坐席数据列表
export const downloadStatus = param => {
	return request.post('/api/user/downloadStatus', qs.stringify(param),{
	    responseType: 'blob'
	})
};



//列表展示系统字段列表
export const getListCustomize = param => {
	return request.post('/api/customize/webListCustomize', qs.stringify(param))
};
// 修改系统字段
export const updateCustomize = param => {
	return request.post('/api/customize/updateCustomize', param, {headers: {'Content-Type': 'application/json'}})
};
//属性启用
export const updateCustomerCustomize = param => {
	return request.post('/api/customize/updateCustomerCustomize', qs.stringify(param))
};
// 添加客户系统字段
export const addCustomize = param => {
	return request.post('/api/customize/addCustomize', param, {headers: {'Content-Type': 'application/json'}})
};
//查询详情
export const findCustomizeByUid = param => {
	return request.post('/api/customize/findCustomizeByUid', qs.stringify(param))
};
//删除字段
export const deleteCustomize = param => {
	return request.post('/api/customize/deleteCustomize', qs.stringify(param))
};
//删除客户来源
export const deleteCustomerSource = param => {
	return request.post('/api/customize/deleteCustomerSource', qs.stringify(param))
};
// 添加客户来源
export const addCustomerSource = param => {
	return request.post('/api/customize/addCustomerSource', param, {headers: {'Content-Type': 'application/json'}})
};
//获取自定义来源列表
export const getCustomerSource = companyId => {
	return request.get('/api/customize/getCustomerSource?companyId=' + companyId)
};
// 添加意向客户
export const addIntentionCustomer = param => {
	return request.post('/api/intentionCustomer/addIntentionCustomer', param, {headers: {	'Content-Type': 'application/json'}})
};
//意向客户详情
export const findDetails = id => {
	return request.get('/api/intentionCustomer/findDetails?id=' + id)
};
//获取自定义进度列表
export const getCustomerSchedule = companyId => {
	return request.get('/api/customize/getCustomerSchedule?companyId=' + companyId)
};
//添加进度
export const addCustomerSchedule = param => {
	return request.post('/api/customize/addCustomerSchedule', qs.stringify(param))
};
//删除进度
export const deleteCustomerSchedule = param => {
	return request.post('/api/customize/deleteCustomerSchedule', qs.stringify(param))
};
//编辑进度
export const updateCustomerSchedule = param => {
	return request.post('/api/customize/updateCustomerSchedule', qs.stringify(param))
};
//释放原因列表
export const getCustomerRelease = companyId => {
	return request.get('/api/customize/getCustomerRelease?companyId=' + companyId)
};
//添加释放原因
export const addCustomerRelease = param => {
	return request.post('/api/customize/addCustomerRelease', qs.stringify(param))
};
//删除释放原因
export const deleteCustomerRelease = param => {
	return request.post('/api/customize/deleteCustomerRelease', qs.stringify(param))
};